/* eslint-disable no-inline-styles/no-inline-styles */
import { OnboardingEvents, trackTagManagerEvent } from 'firebase-client';
import { Checkbox, FormProvider, SubmitHandler, TextInput, useForm, yupResolver } from 'forms';
import { NUMBERS_ONLY_REGEX } from 'forms/validations/constants';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { Box, Form } from 'ui';
import { bool, object, string } from 'yup';

import { useCreateCustomer } from '~/customer';
import { useTradeRegister } from '~/data';
import { ContinueButton, StepTitle } from '~/ui';

interface FormValues {
  eik: string;
  searchInTradeRegister?: boolean;
}

export const CompanyEIKStep = () => {
  const { t } = useTranslation('createCustomerWizard');
  const { goToNextStep, getCustomerData, setEIK, navigateWithRedirect, setCustomerData } = useCreateCustomer();
  const { getCompany } = useTradeRegister();

  const [isLoading, setIsLoading] = useState(false);

  const { eik } = getCustomerData();

  const resolver = yupResolver(
    object({
      eik: string()
        // @ts-expect-error - suppress the error for the translation function
        .required(t('steps.eik.fields.eik.required'))
        .matches(NUMBERS_ONLY_REGEX, { message: t('steps.eik.fields.eik.onlyNumbersField') }),
      searchInTradeRegister: bool(),
    })
  );

  const { handleSubmit, watch, formState, ...rest } = useForm<FormValues>({
    defaultValues: { eik, searchInTradeRegister: false },
    resolver,
  });

  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    trackTagManagerEvent(OnboardingEvents.companyEIK, { user_id: values.eik });
    setEIK(values.eik);

    if (values.searchInTradeRegister) {
      setIsLoading(true);
      const company = await getCompany(values.eik);

      if (company) {
        setCustomerData({
          ...getCustomerData(),
          address: {
            addressLine1: company?.address?.street || getCustomerData().address.addressLine1,
            addressLine2: '',
            area: company.address?.area || getCustomerData().address.area,
            municipality: company.address?.municipality || getCustomerData().address.municipality,
            postCode: company.address?.postCode || getCustomerData().address.postCode,
            region: company.address?.region || getCustomerData().address.region,
            settlement: company.address?.settlement || getCustomerData().address.settlement,
          },
          eik: values.eik,
          legalEntityName: `${company.name} ${company.type}`,
          ...(company.owners?.length && {
            currentRepresentativeIndex: 0,
            representatives: company.owners.map((owner) => ({
              firstName: owner.firstName,
              lastName: owner.lastName,
              middleName: owner.middleName || '',
            })),
          }),
        });
      }
      setIsLoading(false);
    }

    navigateWithRedirect(goToNextStep);
  };

  return (
    <FormProvider {...{ formState, handleSubmit, watch, ...rest }}>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box display="flex" flexDirection="column">
          <Box mb={2}>
            <StepTitle>{t('steps.eik.title')}</StepTitle>
          </Box>

          <Box>
            <TextInput
              required
              margin="normal"
              fullWidth
              label={t('steps.eik.fields.eik.label')}
              // @ts-expect-error - suppress the error for the translation function
              placeholder={t('steps.eik.fields.eik.placeholder')}
              name="eik"
              autoFocus
              shrink
              data-testid="eik"
            />
          </Box>

          <Checkbox
            name="searchInTradeRegister"
            label={t('steps.eik.fields.searchInTradeRegister.label')}
            data-testid="fetch-business-data"
          />
        </Box>

        <ContinueButton data-testid="eik-submit-button" loading={isLoading} disabled={!formState.isValid} />
      </Form>
    </FormProvider>
  );
};
