'use client';

import { BlenderEvents, trackTagManagerEvent } from 'firebase-client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ListPageTitle, SearchControl, SortControl, SortControlOption, useResolution } from 'ui';

import { CustomerPicker, useSelectedCustomerIds } from '~/customer';
import { InvoiceOrderBy } from '~/data/api';
import { LayoutSwitcher, ScrollableViewWithData } from '~/ui';

import { useInvoices } from '../../hooks/useInvoices/useInvoices';
import { InvoiceList } from './InvoiceList';

const UserInvoices = () => {
  const { t } = useTranslation();

  const { selectedCustomerIds: selectedCustomersIds } = useSelectedCustomerIds();
  const [orderBy, setOrderBy] = useState<InvoiceOrderBy>('-invoice_date');
  const [invoiceNumber, setInvoiceNumber] = useState<string>();

  const { isTablet } = useResolution();

  const handleSearch = (invoiceNumberQuery: string) => {
    trackTagManagerEvent(BlenderEvents.invoiceSearch);
    setInvoiceNumber(invoiceNumberQuery);
  };

  const handleSortChange = (option: SortControlOption<InvoiceOrderBy>) => {
    trackTagManagerEvent(BlenderEvents.invoiceOrderBy);
    setOrderBy(option.order);
  };

  const sortOptions: SortControlOption<InvoiceOrderBy>[] = [
    { key: 'date', label: t('invoices:sortByDate'), order: '-invoice_date' },
    { key: 'amount', label: t('invoices:sortByAmountAsc'), order: '+total_invoice_cost' },
    { key: 'amount', label: t('invoices:sortByAmount'), order: '-total_invoice_cost' },
  ];

  return (
    <>
      <ListPageTitle
        title={t('nav.invoices')}
        actions={
          <SearchControl
            name={t('invoices:search')}
            placeholder={t('invoices:search')}
            onSearch={handleSearch}
            data-testid="search-invoices"
            popoverPosition={isTablet ? 'right' : 'left'}
          />
        }
        desktopActions={
          <>
            <CustomerPicker
              sx={{
                mr: 1,
              }}
            />
            <SortControl
              onChange={handleSortChange}
              options={sortOptions}
              sx={{
                mr: 1,
              }}
            />
            <LayoutSwitcher />
          </>
        }
      />

      <ScrollableViewWithData
        useDataHook={useInvoices}
        hookOptions={{
          filterBy: { customerIdIn: selectedCustomersIds.join(','), search: invoiceNumber },
          orderBy,
          size: 12,
        }}
        ListComponent={InvoiceList}
      />
    </>
  );
};

export default UserInvoices;
