'use client';

import { useTranslation } from 'react-i18next';

import { PasswordResetForm } from '~/auth';
import { AuthPage } from '~/ui';

export const ResetPasswordHandler = () => {
  const { t } = useTranslation();
  return (
    <AuthPage title={t('auth:resetPassword')}>
      <PasswordResetForm />
    </AuthPage>
  );
};
