import { formatDate } from 'dates';
import { Trans } from 'react-i18next';
import { Typography } from 'ui';

export const InvoiceDates = ({ date }: { date: Date }) => {
  return (
    <Typography>
      <Trans
        i18nKey="invoices:date"
        values={{ date: formatDate({ date }) }}
        components={[<Typography display="inline" component="span" fontWeight="bold" />]}
      />
    </Typography>
  );
};
