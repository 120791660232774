'use client';

import { useTranslation } from 'react-i18next';
import {
  Checkbox,
  ListItemText,
  Loader,
  MenuItem,
  SelectChangeEvent,
  SelectField,
  SxProps,
  Theme,
  Typography,
  TypographyOverflow,
} from 'ui';

import { Customer } from '~/data';

import { useCustomers } from '../../hooks/useCustomers/useCustomers';
import { useSelectedCustomerIds } from '../../hooks/useSelectedCustomerIds/useSelectedCustomerIds';
import { CustomerCountBadge } from '../shared';

interface Props {
  sx?: SxProps<Theme>;
}

const findSelectedCustomer = (selected: string[], customer: Customer[]) =>
  selected
    .map((selectedCustomer: string) => customer.find(({ customerId }) => selectedCustomer === customerId)?.name)
    .join(', ');

export const CustomerPicker = ({ sx }: Props) => {
  const { t } = useTranslation();
  const { selectedCustomerIds: selectedCustomersIds, setSelectedCustomerIds: setSelectedCustomersIds } =
    useSelectedCustomerIds();
  const { data, isLoading } = useCustomers();

  const customersData = data?.items || [];

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;

    if (!value.length) {
      setSelectedCustomersIds([]);
      return;
    }

    // On autofill we get a stringified value.
    setSelectedCustomersIds(typeof value === 'string' ? value.split(',') : value);
  };

  const renderValue = (selected: string[]) => (
    <Typography component="span" ml={1}>
      {selected.length === 0 ? t('customerPickerLabel') : findSelectedCustomer(selected, customersData)}
    </Typography>
  );

  return (
    <SelectField<string[]>
      multiple
      displayEmpty
      value={selectedCustomersIds}
      onChange={handleChange}
      sx={{
        width: 210,
        ...sx,
      }}
      startAdornment={<CustomerCountBadge count={selectedCustomersIds.length} />}
      renderValue={renderValue}
      data-testid="customer-picker"
      variant="outlined"
    >
      {isLoading ? (
        <Loader />
      ) : (
        customersData.map(({ customerId, name }) => (
          <MenuItem
            key={customerId}
            value={customerId}
            sx={({ spacing }) => ({
              maxWidth: spacing(40),
            })}
          >
            <Checkbox name="" checked={Boolean(selectedCustomersIds.find((selectedId) => selectedId === customerId))} />
            <ListItemText primary={<TypographyOverflow>{name}</TypographyOverflow>} />
          </MenuItem>
        ))
      )}
    </SelectField>
  );
};
