'use client';

import { TFunction } from 'i18next';
import NextLink from 'next/link';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AvatarGroup, Button, DataTable, DataTableColumns } from 'ui';

import { Customer, getAddress } from '~/data';

import getCustomerPath from '../../helpers/getCustomerPath';
import getCustomerRepresentatives from '../../helpers/getCustomerRepresentatives';

type RenderProps = {
  row: Customer;
};

const RepresentativesCell = ({ row }: RenderProps) => <AvatarGroup users={getCustomerRepresentatives(row)} />;

const CustomerPathButton = ({ row, t }: RenderProps & { t: TFunction }) => {
  return (
    <Button
      LinkComponent={NextLink}
      href={getCustomerPath(row)}
      color="limeGreen"
      size="lg"
      data-testid="see-more-customer"
    >
      {t('table.goTo')}
    </Button>
  );
};

export const buildTableColumns = (t: TFunction): DataTableColumns<Customer>[] => [
  { accessor: 'name', header: t('table.name') },
  { accessor: 'customerId', header: t('table.id') },
  {
    accessor: 'address',
    header: t('table.address'),
    value: ({ row }) => getAddress(row.address),
  },
  { accessor: 'representatives', header: t('table.representatives'), value: RepresentativesCell },
  { header: '', value: (props) => <CustomerPathButton {...props} t={t} />, virtualColumn: 'path' },
];

export const CustomerTable = ({ list }: { list: Customer[] }) => {
  const { t } = useTranslation('customers');

  const tableColumns = useMemo(() => buildTableColumns(t), [t]);

  return <DataTable<Customer> columns={tableColumns} uid="customerId" data-testid="customer-table" data={list} />;
};
