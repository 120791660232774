'use client';

import { MeteringPoint } from '~/data';
import { useLayoutStore } from '~/store';
import { NoResultsMessage } from '~/ui';

import { MeteringPointCardList } from './MeteringPointCardList';
import { MeteringPointTable } from './MeteringPointTable';

export const MeteringPointsList = ({ list }: { list: MeteringPoint[] }) => {
  const { layout } = useLayoutStore();

  if (!list) {
    return <NoResultsMessage />;
  }

  if (layout === 'table') {
    return <MeteringPointTable list={list} />;
  }

  return <MeteringPointCardList list={list} />;
};
