'use client';

import { useParams } from 'next/navigation';
import { useTranslation } from 'react-i18next';
import { addWarning, Warnings } from 'rum';

import { MeteringPointDetails } from '../detail';

const MeteringPointDetailsContainer = () => {
  const { t } = useTranslation();

  const params = useParams();
  const id = params && params.id;

  if (typeof id !== 'string') {
    addWarning(Warnings.INVALID_QUERY_PARAM, { page: `metering-points/${id}`, query: id });
  }

  return typeof id === 'string' ? <MeteringPointDetails id={id} /> : <p>{t('customers:customer.notFound')}</p>;
};

export default MeteringPointDetailsContainer;
