import { IfFeatureEnabled } from 'feature-flag';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Actions, addAction } from 'rum';
import { Box, DownloadButton, useResolution } from 'ui';

import { recordInvoiceDownload } from '../../helpers/getInvoicePath/recordInvoiceDownload';
import { useInvoice } from '../../hooks/useInvoice/useInvoice';

interface Props {
  invoiceId: string;
}

export const DownloadButtons = ({ invoiceId }: Props) => {
  const { t } = useTranslation('invoices');
  const { isTablet } = useResolution();
  const { data: invoice } = useInvoice({ invoiceId });

  const buttonSize = isTablet ? 'lg' : 'sm';

  return (
    <Box
      sx={{
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
      }}
    >
      {invoice && invoice.hourlyInquiryUrl && (
        <IfFeatureEnabled feature="invoice_hourly_report">
          <DownloadButton
            downloadUrl={invoice.hourlyInquiryUrl}
            size={buttonSize}
            variant="secondary"
            onClick={() => {
              addAction(Actions.BLENDER_DOWNLOAD_HOURLY_REPORT, {
                invoiceId: invoice.invoiceId,
                invoiceNumber: invoice.invoiceNumber,
                pdfUrl: invoice.pdfUrl,
              });
            }}
          >
            {t('downloadHourlyReport')}
          </DownloadButton>
        </IfFeatureEnabled>
      )}
      {invoice && invoice.pdfUrl && (
        <IfFeatureEnabled feature="invoice_download_pdf">
          <Box ml={1}>
            <DownloadButton
              downloadUrl={invoice.pdfUrl}
              size={buttonSize}
              onClick={() => {
                recordInvoiceDownload(invoice);
              }}
            >
              {t('downloadPdf')}
            </DownloadButton>
          </Box>
        </IfFeatureEnabled>
      )}
    </Box>
  );
};
