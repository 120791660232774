import { useTranslation } from 'react-i18next';
import { BillingPlans } from 'types';
import { Box, Link, Typography } from 'ui';

import { useIsContinueOnboardingEnabled } from '~/onboarding/hooks';

export const OnboardingBanner = () => {
  const onboardingData = useIsContinueOnboardingEnabled();
  const { t } = useTranslation('common');

  if (!onboardingData.isEnabled || !onboardingData.data?.customerData?.planId) {
    return null;
  }

  const planId =
    Object.keys(BillingPlans)[Object.values(BillingPlans).indexOf(onboardingData.data?.customerData?.planId)];

  return (
    <Box
      sx={{
        backgroundColor: 'primary',
        display: 'flex',
        justifyContent: 'center',
        padding: 2,
      }}
    >
      <Box mt={2} bgcolor="white" p={2} borderRadius={5} display="flex" flexDirection="row">
        <Typography>
          <Typography component="span" color="black">
            {t('onboardingBanner.message')}
          </Typography>
          &nbsp;
          <Link href={`/onboarding?plan=${planId}`} data-testid="continue-onboarding-process">
            <Typography component="span" color="textColor">
              {t('onboardingBanner.button')}
            </Typography>
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};
