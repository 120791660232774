'use client';

import { BlenderEvents, trackTagManagerEvent } from 'firebase-client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, ListPageTitle, SearchControl, useResolution } from 'ui';

import { CustomerPicker, useSelectedCustomerIds } from '~/customer';
import { MeteringPointsOptions } from '~/data/api';
import { LayoutSwitcher, ScrollableViewWithData } from '~/ui';

import { useMeteringPoints } from '../../hooks/useMeteringPoints/useMeteringPoints';
import { CustomerTypeSelect } from '../shared';
import { MeteringPointsList } from './MeteringPointsList';

const UserMeteringPoints = () => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState<string>();
  const { selectedCustomerIds } = useSelectedCustomerIds();
  const [customerType, setCustomerType] = useState<MeteringPointsOptions['customerType']>('owner');

  const { isTablet } = useResolution();

  const handleSearch = (q: string) => {
    trackTagManagerEvent(BlenderEvents.meteringPointSearch);
    setSearchQuery(q);
  };

  const onCustomerTypeChange = (value: MeteringPointsOptions['customerType']) => {
    setCustomerType(value);
  };

  return (
    <>
      <ListPageTitle
        title={t('nav.meteringPoints')}
        desktopActions={
          <>
            <Box mr={1}>
              <CustomerTypeSelect customerType={customerType} onCustomerTypeChange={onCustomerTypeChange} />
            </Box>

            <CustomerPicker
              sx={{
                mr: 1,
              }}
            />

            <LayoutSwitcher />
          </>
        }
        actions={
          <SearchControl
            name={t('meteringPoints:search')}
            placeholder={t('meteringPoints:search')}
            onSearch={handleSearch}
            popoverPosition={isTablet ? 'right' : 'left'}
          />
        }
      />

      <ScrollableViewWithData
        useDataHook={useMeteringPoints}
        hookOptions={{
          filterBy: { customerType, ownerIdIn: selectedCustomerIds.join(','), search: searchQuery },
        }}
        ListComponent={MeteringPointsList}
      />
    </>
  );
};

export default UserMeteringPoints;
