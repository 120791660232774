import { useTranslation } from 'react-i18next';
import { MenuItem, SelectChangeEvent, SelectField } from 'ui';

import { MeteringPointsOptions } from '~/data/api';

const OPTIONS: {
  label: string;
  value: MeteringPointsOptions['customerType'];
}[] = [
  {
    label: 'all',
    value: 'owner',
  },
  {
    label: 'leased',
    value: 'contract',
  },
];

interface CustomerTypeSelectProps {
  customerType: MeteringPointsOptions['customerType'];
  onCustomerTypeChange: (value: MeteringPointsOptions['customerType']) => void;
}

export const CustomerTypeSelect = ({ customerType, onCustomerTypeChange }: CustomerTypeSelectProps) => {
  const { t } = useTranslation('meteringPoints');

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const { value } = event.target;

    onCustomerTypeChange(value as MeteringPointsOptions['customerType']);
  };

  return (
    <SelectField<MeteringPointsOptions['customerType']> value={customerType} onChange={handleChange} variant="outlined">
      {OPTIONS.map(({ value, label }) => (
        <MenuItem key={value} value={value}>
          {t(label)}
        </MenuItem>
      ))}
    </SelectField>
  );
};
