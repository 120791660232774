'use client';

import { Trans, useTranslation } from 'react-i18next';
import { StatsList, Typography } from 'ui';

interface Props {
  data: { key: string; value: string }[];
}

export const CustomerStats = ({ data }: Props) => {
  const { t } = useTranslation('customers');

  const stats = data.map(({ key, value }) => ({
    content: (
      <Trans
        i18nKey={`customers:statsCard.${key}.description`}
        values={{ value }}
        components={[
          <Typography color="primary.main" fontWeight="bold" variant="h5" display="inline" />,
          <Typography variant="h6" fontWeight="bold" display="inline" />,
        ]}
      />
    ),
    title: t(`statsCard.${key}.title`),
  }));

  return <StatsList stats={stats} />;
};
