'use client';

import logger from 'logger';
import { useRouter, useSearchParams } from 'next/navigation';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { addError, Errors } from 'rum';

import { AuthTranslation, SignUpForm } from '~/auth';
import { AuthPage } from '~/ui';

export const SignupHandler = () => {
  const { t } = useTranslation();
  const searchParams = useSearchParams();
  const { push } = useRouter();

  const preselectedEmail = searchParams ? searchParams.get('email') : undefined;

  const onSignup = useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      try {
        logger.debug('post sign up login', { email, password });
        const { loginUserWithPassword } = await import('~/auth');
        await loginUserWithPassword({ email, password });

        logger.debug('redirecting to /invoices');
        push('/invoices');
      } catch (error) {
        addError(Errors.AUTH_SIGN_UP_LOGIN_FAILURE, error, {
          email,
          password,
        });
      }
    },
    [push]
  );

  return (
    <AuthPage title={t('auth:signupTitle')}>
      <SignUpForm preselectedEmail={preselectedEmail as string} onSignup={onSignup} />
      <AuthTranslation i18nKey="auth:alreadyHaveAccount" href="/login" />
    </AuthPage>
  );
};
