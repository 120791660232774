'use client';

import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, ScrollableView, Typography } from 'ui';

import { UsePaginatedQueryResult } from '~/data/api';

import { NavigationLayoutLoader } from '../NavigationLayoutLoader/NavigationLayoutLoader';

interface ScrollableViewWithDataProps<T, O> {
  useDataHook: (options: O) => UsePaginatedQueryResult<T>;
  hookOptions: O;
  ListComponent: React.ComponentType<{ list: T[] }>;
}

const DataList = <T, O>({ useDataHook, hookOptions, ListComponent }: ScrollableViewWithDataProps<T, O>) => {
  const { t } = useTranslation();
  const { data, isLoading, fetchNextPage } = useDataHook(hookOptions);

  return (
    <>
      <Box mb={2}>
        <Typography variant="h6" data-testid="result-count">
          {t('resultCount', { count: data.total })}
        </Typography>
      </Box>

      <ScrollableView
        listComponent={<ListComponent list={data.items} />}
        isLoading={isLoading}
        fetchNextPage={fetchNextPage}
      />
    </>
  );
};

export const ScrollableViewWithData = <T, O>({
  useDataHook,
  hookOptions,
  ListComponent,
}: ScrollableViewWithDataProps<T, O>) => {
  return (
    <Suspense fallback={<NavigationLayoutLoader />}>
      <DataList useDataHook={useDataHook} hookOptions={hookOptions} ListComponent={ListComponent} />
    </Suspense>
  );
};
