'use client';

import { useTranslation } from 'react-i18next';
import {
  Box,
  CurrencyFormat,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from 'ui';

import { InvoiceLineItem } from '~/data';

interface Props {
  totalInvoiceCost: number;
  items: InvoiceLineItem[];
}

export const InvoiceMeteringPointTable = ({ items, totalInvoiceCost }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation('invoices');

  return (
    <>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow backgroundColor={theme.palette.gradient.darkGreen} rounded={false}>
              <TableCell color="white">{t('service')}</TableCell>
              <TableCell color="white">{t('unit')}</TableCell>
              <TableCell color="white">{t('quantity')}</TableCell>
              <TableCell color="white">{t('price')}</TableCell>
              <TableCell color="white">{t('amount')}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {items.map(({ invoiceLineItemId, name, unit, quantity, price, totalCost }) => (
              <TableRow key={invoiceLineItemId}>
                <TableCell>{name} *</TableCell>
                <TableCell>{unit}</TableCell>
                <TableCell>{quantity}</TableCell>
                <TableCell>{price}</TableCell>
                <TableCell>
                  <CurrencyFormat value={totalCost} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Typography fontStyle="italic" variant="caption">
        {t('invoiceItemsTableNote')}
      </Typography>

      <Stack direction="row" justifyContent="flex-end">
        <Typography align="right" fontWeight="bold">
          {t('total')}:
        </Typography>

        <Box ml={4} width={100}>
          <CurrencyFormat value={totalInvoiceCost} currency="BGN" fontWeight="bold" />
        </Box>
      </Stack>
    </>
  );
};
