'use client';

import logger from 'logger';
import { useRouter } from 'next/navigation';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthTranslation, LoginForm } from '~/auth';
import { AuthPage } from '~/ui';

export const LoginHandler = () => {
  const { push } = useRouter();
  const { t } = useTranslation();

  const onLogin = useCallback(async () => {
    logger.debug('redirecting to /invoices');
    await push('/invoices');
  }, [push]);

  return (
    <AuthPage title={t('auth:login')}>
      <LoginForm onLogin={onLogin} />
      <AuthTranslation i18nKey="auth:register" href="/signup" />
    </AuthPage>
  );
};
