'use client';

import { DocumentData } from 'firebase/firestore';
import { useSearchParams } from 'next/navigation';
import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';
import { BillingPlans, isSupportedPlan } from 'types';

import { CreateCustomerProvider, useCreateCustomer, useCustomers } from '~/customer';
import { CreateCustomerState } from '~/customer/hooks/useCreateCustomer/types';
import { OnboardingRequest } from '~/services/firestore';
import { MobilePageHeader, ProcessDetails, WizardLayout } from '~/ui';
import { Steps } from '~/utils';

import { AccountManagerStep } from './AccountManagerStep';
import { BillingContactPersonStep } from './BillingContactPersonStep';
import { BusinessConfirmationStep } from './BusinessConfirmationStep';
import { BusinessContactPersonStep } from './BusinessContactPersonStep';
import { BusinessRepresentative1Step } from './BusinessRepresentative1Step';
import { BusinessRepresentative2Step } from './BusinessRepresentative2Step';
import { BusinessStepsPreviewStep } from './BusinessStepsPreviewStep';
import { CompanyEIKStep } from './CompanyEIKStep';
import { CustomerAddressStep } from './CustomerAddressStep';
import { CustomerNameStep } from './CustomerNameStep';
import { CustomerTypeStep } from './CustomerTypeStep';
import { FillInOptionsStep } from './FillInOptionsStep';
import { InvoiceUploadStep } from './InvoiceUploadStep';
import { LegalEntityNameStep } from './LegalEntityNameStep';
import { LoaderView } from './LoaderView';
import { PersonalIdStep } from './PersonalIdStep';
import { RegisteredCustomerMessage } from './RegisteredCustomerMessage';
import { ResidentConfirmationStep } from './ResidentConfirmationStep';
import { ResidentStepsPreviewStep } from './ResidentStepsPreviewStep';

const STEPS = {
  accountManager: AccountManagerStep,
  address: CustomerAddressStep,
  billingContactPerson: BillingContactPersonStep,
  businessConfirmation: BusinessConfirmationStep,
  businessRepresentative1: BusinessRepresentative1Step,
  businessRepresentative2: BusinessRepresentative2Step,
  businessStepsPreview: BusinessStepsPreviewStep,
  contactPerson: BusinessContactPersonStep,
  customerType: CustomerTypeStep,
  eikId: CompanyEIKStep,
  fillInOptions: FillInOptionsStep,
  invoiceUpload: InvoiceUploadStep,
  legalEntityName: LegalEntityNameStep,
  name: CustomerNameStep,
  personalId: PersonalIdStep,
  residentConfirmation: ResidentConfirmationStep,
  residentStepsPreview: ResidentStepsPreviewStep,
};

interface CurrentStepProps {
  total: number;
  CurrentStepComponent: React.ReactNode;
}

const StepComponent = ({ total, CurrentStepComponent }: CurrentStepProps) => {
  if (total > 0) {
    return <RegisteredCustomerMessage />;
  }

  return CurrentStepComponent;
};

const Wizard = ({ onInvoiceUpload }: { onInvoiceUpload?: (data: OnboardingRequest) => void }) => {
  const { t } = useTranslation();
  const { getCurrentStep, goToPreviousStep, navigatorSteps, setPlan } = useCreateCustomer();
  const currentStep = getCurrentStep();
  const searchParams = useSearchParams();
  const plan = searchParams && searchParams.get('plan');

  const { isLoading, data } = useCustomers({ size: 1 });

  useEffect(() => {
    if (typeof plan === 'string' && isSupportedPlan(plan)) {
      setPlan(BillingPlans[plan as keyof typeof BillingPlans]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const CurrentStep = STEPS[currentStep as keyof typeof STEPS] || STEPS.customerType;

  const showBackButton = navigatorSteps.includes('accountManager')
    ? currentStep !== 'accountManager' && data?.total === 0
    : currentStep !== 'customerType' && data?.total === 0;

  return (
    <WizardLayout
      leftChild={
        <ProcessDetails
          title={t('createCustomerWizard:title')}
          subtitle={t('createCustomerWizard:subtitle')}
          onBackClick={goToPreviousStep}
          showBackButton={showBackButton}
          iconSource="/images/onboarding-icons/customer-data-icon.svg"
        />
      }
      rightChild={
        isLoading ? (
          <LoaderView />
        ) : (
          <StepComponent
            total={data?.total || 0}
            CurrentStepComponent={<CurrentStep onInvoiceUpload={onInvoiceUpload} />}
          />
        )
      }
      header={
        <MobilePageHeader
          title={t('createCustomerWizard:title')}
          onBackClick={goToPreviousStep}
          showBackButton={showBackButton}
        />
      }
    />
  );
};

interface CreateCustomerWizardProps {
  onCompletion: () => void;
  onUpdate: (data: CreateCustomerState['customerData']) => void;
  onInvoiceUpload?: (data: OnboardingRequest) => void;
  onboardingData?: DocumentData | null;
  skipSteps?: Steps;
}

export const CreateCustomerWizard = ({
  onCompletion,
  onUpdate,
  onInvoiceUpload,
  onboardingData,
  skipSteps,
}: CreateCustomerWizardProps) => (
  <CreateCustomerProvider
    skipSteps={skipSteps}
    onCompletion={onCompletion}
    onUpdate={onUpdate}
    onboardingData={onboardingData}
  >
    <Wizard onInvoiceUpload={onInvoiceUpload} />
  </CreateCustomerProvider>
);
