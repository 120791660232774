'use client';

import { formatDate } from 'dates';
import { Trans, useTranslation } from 'react-i18next';
import { Avatar, Box, Grid, Typography } from 'ui';

import { getAddress } from '~/data';

import { useInvoice } from '../../../hooks/useInvoice/useInvoice';
import { InvoiceDues, InvoiceItemsTable } from '../../list';
import { InvoiceDates } from '../../shared';
import { CompanyAvatarDetails } from '../CompanyAvatarDetails';
import { GradientDivider } from './styled';

interface Props {
  invoiceId: string;
}

export const InvoiceSummary = ({ invoiceId }: Props) => {
  const { t } = useTranslation();

  const { data: invoice } = useInvoice({ invoiceId });

  return (
    invoice && (
      <>
        <Grid container justifyContent="space-between" columnSpacing={{ lg: 20, xs: 5 }} rowSpacing={4} mb={2}>
          <Grid item xs={12} md={6}>
            <CompanyAvatarDetails
              avatar={<Avatar shape="rounded" name={invoice.customer.name} size="xlarge" withGradient />}
              description={t('invoices:recipient')}
              name={invoice.customer.name}
              address={getAddress(invoice.customer.address)}
              detailedData={[
                t('invoices:bulstat', { value: invoice.customer.customerId }),
                t('invoices:vatId', { value: invoice.customer.vatNo }),
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <CompanyAvatarDetails
              avatar={
                <Avatar
                  shape="rounded"
                  name={t('brand:companyName')}
                  size="xlarge"
                  bgColor="white"
                  src="/images/logo-circle.png"
                />
              }
              description={t('invoices:supplier')}
              name={t('brand:companyName')}
              address={t('brand:address')}
              detailedData={[
                t('invoices:bulstat', { value: t('brand:bulstat') }),
                t('invoices:vatId', { value: t('brand:bulstat') }),
                t('invoices:revenueTaxNumber', { value: t('brand:revenueTaxNumber') }),
                t('invoices:accountablePerson', { value: t('brand:accountablePerson') }),
              ]}
            />
          </Grid>
        </Grid>

        <GradientDivider />

        <Box mb={4}>
          <InvoiceDates date={invoice.invoiceDate} />
        </Box>

        <Box mb={2}>
          <InvoiceItemsTable items={invoice.lineItems} />
        </Box>

        <Box mb={2}>
          <InvoiceDues
            items={[
              { name: t('invoices:totalCost'), value: invoice.totalCost },
              { name: t('invoices:vat', { value: 20 }), value: invoice.vat },
              { name: t('invoices:totalInvoiceCost'), value: invoice.totalInvoiceCost },
            ]}
            currency="BGN"
          />
        </Box>

        <Typography>
          <Trans
            i18nKey="invoices:totalInvoiceWords"
            values={{ totalInvoiceWords: invoice.totalInvoiceCostWords }}
            components={[<Typography display="inline" component="span" fontWeight="bold" />]}
          />
        </Typography>

        <Typography>
          <Trans
            i18nKey="invoices:dueDate"
            values={{ dueDate: formatDate({ date: invoice.paymentDeadline }) }}
            components={[<Typography display="inline" component="span" fontWeight="bold" />]}
          />
        </Typography>
      </>
    )
  );
};
